export default {
    appName: 'QTKEY系統',
    lang: {
        'zh-TW': '繁體中文',
        'zh-CN': '簡體中文',
    },
    globalError: '發生錯誤,請聯絡技術人員',
    menu: {
        merchant: {
            appManagement: '產品管理',
            system: {
                detail: '個人帳號管理',
                index: '系統管理選單',
                merchantAccount: '後台帳號管理',
                operationRecord: '操作紀錄管理',
                rolePermissions: '角色權限管理',
            },
        },
    },
    login: {
        backButton: '返回',
        emailLoginButton: '未攜帶裝置登入',
        emailSended: '已發送 登入代碼 至 <br> {email} <br>請盡速至信箱確認',
        passkeyLoginButton: 'QTKEY 登入',
    },
    redirect: {
        errorMessageTitle: '發生錯誤',
        toLoginMessage: '按下確定跳轉至登入頁面',
    },
    header: {
        projectName: 'QTKEY系統',
        language: {
            tw: '繁體',
            cn: '簡體',
        },
        logout: '登出',
    },
    formTemplate: {
        upload: '上傳檔案',
        reset: '重填',
        submit: '確定送出',
        input: '輸入',
        choose: '選擇',
        beforeRemove: '確定移除',
        handleExceed: '最多上傳一張,請先刪除上一選項',
        error: '失敗',
        cancel: '取消',
    },
    dialog: {
        buttonClose: '關閉',
    },
    form: {
        emailRequireMessage: '請輸入email',
        emailFormatMessage: 'email 格式錯誤',
        emailPlaceholder: '請輸入email',
        buttonConfirm: '確定',
        search: '搜尋',
        clear: '清除',
        back: '返回',
        cancel: '取消',
        please: '請',
    },
    table: {
        number: '序列',
        appName: '產品名稱',
        status: '狀態',
        operat: {
            columnName: '操作',
            manage: '管理',
            edit: '編輯',
            delete: '刪除',
        },
        noData: '無資料...',
    },
    errorPage: {
        403: {
            p1: '啊哦~ 你沒有權限訪問該頁面哦',
            p2: '返回首頁',
            p3: '返回上一頁',
        },
        404: {
            p1: '啊哦~ 你所訪問的頁面不存在',
            p2: '返回首頁',
            p3: '返回上一頁',
        },
    },
    dashboard: {
        greet: '歡迎登入 QTKEY系統，請點選左側任一選單開始',
    },
    deviceBind: {
        emailLabel: '信箱(帳號)',
        errorMessageTitle: '錯誤',
        buttonBind: '綁定裝置',
        toLoginMessage: '按下確定跳轉至登入頁面',
        bindSuccessMessage: '綁定成功',
        form: {
            nameLabel: '名稱',
            deviceName: '裝置名稱',
            deviceNameRequire: '請輸入裝置名稱',
        },
    },
    merchant: {
        merchantDetail: {
            descriptions: {
                title: '個人帳號管理',
                btnPasskey: '綁定Passkey',
                btnEdit: '編輯',
                name: '名稱',
                email: '信箱',
                role: '角色',
                device: 'Passkey',
            },
            editAccount: '編輯個人帳號',
            createDevice: '新增裝置名稱',
            form: {
                device: '裝置名稱',
                devicePlaceholder: '請輸入裝置名稱',
                name: '名稱',
                namePlaceholder: '請輸入名稱',
                email: '信箱(帳號)',
                emailPlaceholder: '請輸入信箱(帳號)',
                statusOption: {
                    open: '啟用',
                    close: '停用',
                },
            },
            messageSuccess: '綁定成功',
        },
        appManagement: {
            create: '新增',
            detail: '詳細資訊',
            table: {
                appName: '產品名稱',
                detail: '詳情',
                type: '服務類型',
            },
            confirm: {
                delete: '是否確認刪除?<br/>如果確認刪除，請輸入',
                errorMessage: '此欄位不可空白',
                cancel: '取消',
                confirm: '確定',
            },
            form: {
                appType: '產品類型',
                appName: '產品名稱',
                appNamePlaceholder: '請輸入產品名稱',
                rpName: '認證服務名稱',
                rpNamePlaceholder: '請輸入產品的英文名稱',
                rpNameTip: '*格式範例:<span style="color:#543fff">qtkey-pass</span>',
                rpId: '認證網址',
                rpIdPlaceholder: '請輸入認證網址',
                rpIdTip: '*請輸入專案服務的完整網址，QTKEY功能才可生效，<br>格式範例:<span style="color:#543fff">www.qtkeypass.com</span>',
                rpIdOAuth: 'OAuth2平台網址',
                rpIdOAuthPlaceholder: '請輸入OAuth2平台網址',
                rpIdOAuthTip: '注意:域名決定後無法變更。如強制變更，因Passkey規範，用戶之Passkey階會失效，需要重新綁定。如需強制變更，請聯絡我們。',
                redirect: '服務callback位置',
                redirectPlaceholder: '請輸入服務callback位置',
                androidApkKeyPlaceholder: 'ex:android:apk-key-hash:xxxxxxx',
                androidApkKeyTip: '*請輸入您的Android API Key Hash，以允許您的應用建立和存取QTKEY',
                status: '狀態',
                statusOption: {
                    open: '啟用',
                    close: '關閉',
                },
            },
            detailTab: {
                authentication: 'Authentication管理',
                userManagement: '產品用戶管理',
                events: 'Events紀錄',
                basic: '基本管理',
                emailProvider: 'Email Provider',
            },
            authentication: {
                form: {
                    magicLink: 'Magic Link過期時間(秒)',
                    magicLinkPlaceholder: '請輸入Magic Link過期時間(秒)',
                },
            },
            userManagement: {
                create: '新增用戶',
                import: '匯入用戶',
                edit: '編輯用戶',
                emailListDialogTitle: '重複Email',
                importSuccess: '匯入成功',
                table: {
                    identity: '用戶帳號',
                    email: '信箱',
                    createdAt: '註冊日期',
                    lastLogin: '上次登入時間',
                    events: '紀錄',
                    ipAddress: '白名單IP',
                },
                confirm: {
                    delete: '是否確認刪除?<br/>如果確認刪除，請輸入',
                    errorMessage: '此欄位不可空白',
                    cancel: '取消',
                    confirm: '確定',
                },
                form: {
                    identity: '用戶帳號',
                    email: '信箱',
                    status: '狀態',
                    statusOption: {
                        open: '啟用',
                        close: '關閉',
                    },
                    downloadImportDemo: '下載範例檔案',
                    uploadFile: '選擇匯入檔案',
                    ipAddress: '白名單IP',
                },
            },
            events: {
                table: {
                    adminName: '用戶帳號',
                    adminId: '用戶編號',
                    eventType: 'Event Type',
                    createdAt: '執行時間',
                    xForwarded: 'IP位置',
                    device: '裝置',
                },
            },
            basicManagement: {
                form: {
                    appName: '產品名稱',
                    appNamePlaceholder: '請輸入產品名稱',
                    MagicLink: 'Magic Link 驗證路徑',
                    MagicLinkPlaceholder: 'ex: /magic_link/verify',
                    MagicLinkTip: '*Magic Link登錄信件中的跳轉位置，該頁面需驗證Magic Link是否有效',
                    androidApkKeyPlaceholder: 'ex:android:apk-key-hash:xxxxxxx',
                    androidApkKeyTip: '*請輸入您的Android API Key Hash，以允許您的應用建立和存取QTKEY',
                    rpName: '認證服務名稱',
                    rpId: '認證網址',
                    status: '狀態',
                    popconfirm: {
                        applicationId: '確定要重新設置Application Id?',
                        apiSecretKey: '確定要重新設置API SECRET KEY?',
                    },
                    statusOption: {
                        open: '啟用',
                        close: '關閉',
                    },
                    copySuccess: '複製成功',
                },
            },
            OAuthManagement: {
                createOAuth: '新增服務',
                editOAuth: '編輯服務',
                addressTile: 'OAuth2串接地址',
                tableTitle: 'OAuth2 服務',
                table: {
                    name: '名稱',
                    redirect: '服務callback位置',
                },
                form: {
                    appName: '產品名稱',
                    status: '狀態',
                    appNamePlaceholder: '請輸入產品名稱',
                    rpIdOAuth: 'OAuth2平台網址',
                    rpIdOAuthPlaceholder: '請輸入OAuth2平台網址',
                    register: '開放註冊',
                    redirect: '服務callback位置',
                    redirectPlaceholder: '請輸入服務callback位置',
                    popconfirm: {
                        secretKey: '確定要重新設置SECRET KEY?',
                    },
                },
                statusOption: {
                    open: '啟用',
                    close: '關閉',
                },
                oauthConnectUrlLable: {
                    authorize: '授權端點地址',
                    logout: '註銷會話端點地址',
                    token: 'Token端點地址',
                    user: '用戶訊息端點地址',
                },
                copySuccess: '複製成功',
            },
        },
        MerchantAccount: {
            createAccount: '新增帳號',
            editAccount: '編輯帳號',
            createDevice: '新增裝置名稱',
            table: {
                email: '信箱(帳號)',
                name: '名稱',
                roleName: '角色',
                btnBindKey: '綁定Passkey',
                btnSendMessage: '邀請綁定Passkey',
            },
            form: {
                device: '裝置名稱',
                devicePlaceholder: '請輸入裝置名稱',
                name: '名稱',
                namePlaceholder: '請輸入名稱',
                email: '信箱(帳號)',
                emailPlaceholder: '請輸入信箱(帳號)',
                roleId: '角色',
                status: '狀態',
                sendPasskey: '寄送Passkey綁定邀請信',
                statusOption: {
                    open: '啟用',
                    close: '關閉',
                    y: '是',
                    n: '否',
                },
            },
            messageSuccess: '綁定成功',
            confirm: {
                sendEmail: '寄送Passkey邀請信至',
                delete: '是否確認刪除?<br/>如果確認刪除，請輸入',
                errorMessage: '此欄位不可空白',
                cancel: '取消',
                confirm: '確定',
            },
        },
        rolePermissions: {
            createRole: '新增角色',
            editRole: '編輯角色',
            table: {
                name: '用戶名稱',
                remark: '角色描述',
            },
            tree: {
                title: '權限設定',
                merchantTitle: '商戶站系統權限',
                appTitle: '產品管理',
            },
            form: {
                name: '角色名稱',
                namePlaceholder: '請輸入角色名稱',
                remark: '角色敘述',
                remarkPlaceholder: '請輸入角色敘述',
            },
            confirm: {
                delete: '是否確認刪除?<br/>如果確認刪除，請輸入',
                errorMessage: '此欄位不可空白',
                cancel: '取消',
                confirm: '確定',
            },
        },
        operationRecord: {
            table: {
                name: '用戶名稱',
                accountId: '用戶編號',
                url: '操作項目',
                requestData: 'Request',
                createdAt: '執行時間',
                xForwarded: 'IP位置',
                responseCode: '狀態',
            },
        },
    },
}
