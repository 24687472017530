import { routeMiddleware } from '@/utils/middleware'

export default [
    {
        path: '/',
        component: () => import('@/layout/merchant.vue'),
        redirect: '/dashboard',
        beforeEnter: routeMiddleware,
        children: [
            {
                path: '/dashboard',
                component: () => import('@/views/Dashboard/Dashboard.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login/Login.vue'),
    },
    {
        path: '/redirect/:key',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
    },
    {
        path: '/bind',
        name: 'Bind',
        component: () => import('@/views/DeviceBind/DeviceBind.vue'),
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/ErrorPage/403.vue'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/ErrorPage/404.vue'),
    },
]
