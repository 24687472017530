<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import localeCN from 'element-plus/dist/locale/zh-cn.mjs'
import localeTW from 'element-plus/dist/locale/zh-tw.mjs'
import { getDomainName } from '@/api/global/login'
export default {
    computed: {
        locale() {
            switch (this.$store.state.user.language) {
                case 'zh-CN':
                    return localeCN
                case 'zh-TW':
                default:
                    return localeTW
            }
        },
    },
    components: {
        ElConfigProvider,
    },
    async created() {
        let { code, data } = await getDomainName({ rp_id: window.location.host })
        if (code == 200) {
            document.title = data.name + '-' + this.$t('appName')
        } else {
            this.$router.push({ path: '/404' })
            document.title = this.$t('appName')
        }
    },
}
</script>

<style>
@import './assets/css/main.css';
@import './assets/css/color-dark.css';
</style>
