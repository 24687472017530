const DEFAULT_LANG = 'zh-TW'
export const SUPPORT_LANG = ['zh-TW', 'zh-CN']

export const getLanguage = () => {
    let lang = localStorage.getItem('lang') || navigator.language || DEFAULT_LANG

    // 確認語系是否支援
    if (!SUPPORT_LANG.includes(lang)) {
        lang = DEFAULT_LANG
    }

    return lang
}
