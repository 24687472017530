import { getLanguage } from '@/utils/language'
import i18n from '@/plugins/i18n'

const username = localStorage.getItem('ms_username')
const user = {
    state: {
        tagsList: [],
        collapse: false,
        drawer: false,
        isMobile: false,
        username: username,
        language: getLanguage(),
    },

    mutations: {
        delTagsItem(state, data) {
            state.tagsList.splice(data.index, 1)
        },
        setTagsItem(state, data) {
            state.tagsList.push(data)
        },
        setLanguage(state, data) {
            state.language = data
        },
        clearTags(state) {
            state.tagsList = []
        },
        closeTagsOther(state, data) {
            state.tagsList = data
        },
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i]
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data.$router.push(state.tagsList[i + 1].path)
                    } else if (i > 0) {
                        data.$router.push(state.tagsList[i - 1].path)
                    } else {
                        data.$router.push('/')
                    }
                    state.tagsList.splice(i, 1)
                    break
                }
            }
        },
        // 侧边栏折叠
        hadndleCollapse(state, data) {
            state.collapse = data
        },
        // 侧边栏抽屜mobile
        hadndleDrawer(state, data) {
            state.drawer = data
        },
        // 是否mobile
        hadndleIsMobile(state, data) {
            state.isMobile = data
        },
        //拿取myIngo
        getUserName(state, data) {
            state.username = data
            localStorage.setItem('ms_username', data)
        },
    },

    actions: {
        updateUserLanguage({ commit }, data) {
            i18n.global.locale.value = data
            localStorage.setItem('lang', data)
            commit('setLanguage', data)
        },
    },
}

export default user
