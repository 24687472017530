import i18n from '@/plugins/i18n'
import axios from 'axios'
import { getCookie, setCookie, deleteCookie } from './cookie'
import router from '../router/index'

const { t } = i18n.global

const service = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/merchant/`,
    timeout: 15000,
})

service.interceptors.request.use(
    (config) => {
        let token = getCookie('__tk')
        if (token && config.url !== '/login') {
            config.headers.Authorization = token
        }
        return config
    },
    (error) => {
        console.log(error)
        return Promise.reject()
    }
)

service.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            // 如果回傳的資料有 Authorization 則需要重新設定 cookie
            if (response.data.Authorization) {
                setCookie('__tk', response.data.Authorization, 14, 'days')
            }

            // 轉換錯誤訊息
            if (response.data.code !== 200 && response.data.code !== 300) {
                try {
                    // 是否需要回到登入頁面
                    if (response.data.code == 501) {
                        deleteCookie('__tk')
                        router.push('/login')
                        return Promise.reject('Auth denied redirect to login')
                    }

                    // 是否需要回到登入頁面
                    if (response.data.code == 403) {
                        router.push('/403')
                        return Promise.reject('403')
                    }

                    let code = response.data.message_codes[0].toUpperCase()
                    let message = ''

                    if (code.startsWith('ADMIN.')) {
                        code = code.replaceAll('.', '_')
                        message = t(`errorCode.${code}`)
                    } else {
                        // 取得參數名稱
                        code = code.split('.')
                        let attribute = code[0]

                        // 移除Error code參數名稱
                        code.slice(1)

                        // 將 code 轉成 i18n 參數
                        code = code.join('_')
                        message = t(`${attribute} ${t(`errorCode.${code}`)}`)
                    }
                    response.data.message = message
                    Promise.reject(message)
                } catch (err) {
                    response.data.message = t(`errorCode.COMMON_ERROR`)
                    Promise.reject(t(`errorCode.COMMON_ERROR`))
                }
            }
            return response.data
        } else {
            response.data.message = t(`errorCode.COMMON_ERROR`)
            Promise.reject(t(`errorCode.COMMON_ERROR`))
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default service
