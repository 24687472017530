import { getCookie } from '@/utils/cookie'

export const routeMiddleware = (to, from, next) => {
    // 如果 Cookie 內沒有 token 回傳 false
    let token = getCookie('__tk')

    if (!token) {
        next('/login')
    } else {
        next()
    }
    // next()
}
