import { createI18n } from 'vue-i18n'
import { getLanguage } from '@/utils/language.js'
import messages from '@/utils/i18n/index.js'

const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    allowComposition: true,
    locale: getLanguage(),
    messages: messages,
})

export default i18n
