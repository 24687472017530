export default {
    errorCode: {
        COMMON_ERROR: '不明錯誤',
        ADMIN_FAILCREATE: '創建失敗',
        ADMIN_FAILUPDATE: '更新失敗',
        ADMIN_FAILDELETE: '刪除失敗',
        ADMIN_FAIL: '失敗',
        ADMIN_TOPSLAVE: 'ADMIN_TOPSLAVE',
        ADMIN_ATLEASTHAVEONEAUTH: 'ADMIN_ATLEASTHAVEONEAUTH',
        ADMIN_LOSINGDATA: '資料缺失',
        ADMIN_NOAUTHORITY: 'ADMIN_NOAUTHORITY',
        ADMIN_SYSTEMERROR: '系統錯誤',
        ADMIN_DELETECONFIRMFAIL: '刪除失敗',
        ADMIN_AUTH_ACCOUNTVERIFICATIONFAILD: '帳號驗證失敗',
        ADMIN_AUTH_LOGINAGAIN: '請再次登入',
        ADMIN_AUTH_PLSLOGIN: '請先登入',
        ADMIN_AUTH_ILLEGALPARENT: 'ADMIN_AUTH_ILLEGALPARENT',
        ADMIN_AUTH_HAVINGCHILD: 'ADMIN_AUTH_HAVINGCHILD',
        ADMIN_AUTH_AUTHNOTEXISTS: '權限不存在',
        ADMIN_AUTH_USERNOTEXIST: '使用者不存在',
        ADMIN_AUTH_ACCOUNTDISABLE: '帳號無權限',
        ADMIN_AUTH_TOKENEXPIRED: '登入權限過期',
        ADMIN_LOGIN_USERNOTEXISTS: '使用者不存在',
        ADMIN_LOGIN_EXPIRE: '過期',
        ADMIN_LOGIN_ANDROIDAPKKEYHASHMISMATCH: 'ADMIN_LOGIN_ANDROIDAPKKEYHASHMISMATCH',
        ADMIN_MERCHANT_RPIDEXISTS: '參數錯誤',
        ADMIN_MERCHANT_EMAILEXISTS: 'email 不存在',
        ADMIN_MAILBINDPASSKEY_EXPIRE: '信件過期',
        ADMIN_MAILBINDPASSKEY_INVALIDEMAIL: 'email 錯誤',
        ADMIN_APPS_APPNOTEXISTS: 'App 不存在',
        ADMIN_APPS_APPLICATIONIDNOTEXISTS: 'App id 不存在',
        ADMIN_APPS_EMAILEXISTS: 'email 不存在',
        ADMIN_APPS_NOTENABLED: 'App 無法使用',
        ADMIN_APPUSER_IMPORTUSERERROR: 'App user 匯入錯誤',
        ADMIN_ROLE_EXISTRELATEUSER: 'ADMIN_ROLE_EXISTRELATEUSER',
        ACCEPTED: '參數錯誤',
        ACCEPTED_IF: '參數錯誤',
        ACTIVE_URL: '參數錯誤',
        AFTER: '參數錯誤',
        AFTER_OR_EQUAL: '參數錯誤',
        ALPHA: '參數錯誤',
        ALPHA_DASH: '參數錯誤',
        ALPHA_NUM: '參數錯誤',
        ARRAY: '參數錯誤',
        BEFORE: '參數錯誤',
        BEFORE_OR_EQUAL: '參數錯誤',
        BETWEEN_NUMERIC: '參數錯誤',
        BETWEEN_FILE: '參數錯誤',
        BETWEEN_STRING: '參數錯誤',
        BETWEEN_ARRAY: '參數錯誤',
        BOOLEAN: '參數錯誤',
        CONFIRMED: '參數錯誤',
        CURRENT_PASSWORD: '參數錯誤',
        DATE: '日期錯誤',
        DATE_EQUALS: '參數錯誤',
        DATE_FORMAT: '日期錯誤',
        DECLINED: '參數錯誤',
        DECLINED_IF: '參數錯誤',
        DIFFERENT: '參數錯誤',
        DIGITS: '參數錯誤',
        DIGITS_BETWEEN: '參數錯誤',
        DIMENSIONS: '參數錯誤',
        DISTINCT: '參數錯誤',
        EMAIL: '參數錯誤',
        ENDS_WITH: '參數錯誤',
        ENUM: '參數錯誤',
        EXISTS: '參數錯誤',
        FILE: '參數錯誤',
        FILLED: '參數錯誤',
        GT_NUMERIC: '參數錯誤',
        GT_FILE: '參數錯誤',
        GT_STRING: '參數錯誤',
        GT_ARRAY: '參數錯誤',
        GTE_NUMERIC: '參數錯誤',
        GTE_FILE: '參數錯誤',
        GTE_STRING: '參數錯誤',
        GTE_ARRAY: '參數錯誤',
        IMAGE: '參數錯誤',
        IN: '參數錯誤',
        IN_ARRAY: '參數錯誤',
        INTEGER: '參數錯誤',
        IP: 'IP錯誤',
        IPV4: 'IP錯誤',
        IPV6: 'IP錯誤',
        JSON: '參數錯誤',
        LT_NUMERIC: '參數錯誤',
        LT_FILE: '參數錯誤',
        LT_STRING: '參數錯誤',
        LT_ARRAY: '參數錯誤',
        LTE_NUMERIC: '參數錯誤',
        LTE_FILE: '參數錯誤',
        LTE_STRING: '參數錯誤',
        LTE_ARRAY: '參數錯誤',
        MAC_ADDRESS: '參數錯誤',
        MAX_NUMERIC: '參數錯誤',
        MAX_FILE: '參數錯誤',
        MAX_STRING: '參數錯誤',
        MAX_ARRAY: '參數錯誤',
        MIMES: '參數錯誤',
        MIMETYPES: '參數錯誤',
        MIN_NUMERIC: '參數錯誤',
        MIN_FILE: '參數錯誤',
        MIN_STRING: '參數錯誤',
        MIN_ARRAY: '參數錯誤',
        MULTIPLE_OF: '參數錯誤',
        NOT_IN: '參數錯誤',
        NOT_REGEX: '參數錯誤',
        NUMERIC: '參數錯誤',
        PASSWORD: '參數錯誤',
        PRESENT: '參數錯誤',
        PROHIBITED: '參數錯誤',
        PROHIBITED_IF: '參數錯誤',
        PROHIBITED_UNLESS: '參數錯誤',
        PROHIBITS: '參數錯誤',
        REGEX: '參數錯誤',
        REQUIRED: '參數錯誤',
        REQUIRED_ARRAY_KEYS: '參數錯誤',
        REQUIRED_IF: '參數錯誤',
        REQUIRED_UNLESS: '參數錯誤',
        REQUIRED_WITH: '參數錯誤',
        REQUIRED_WITH_ALL: '參數錯誤',
        REQUIRED_WITHOUT: '參數錯誤',
        REQUIRED_WITHOUT_ALL: '參數錯誤',
        SAME: '參數錯誤',
        SIZE_NUMERIC: '參數錯誤',
        SIZE_FILE: '參數錯誤',
        SIZE_STRING: '參數錯誤',
        SIZE_ARRAY: '參數錯誤',
        STARTS_WITH: '參數錯誤',
        STRING: '參數錯誤',
        TIMEZONE: '參數錯誤',
        UNIQUE: '參數錯誤',
        UPLOADED: '參數錯誤',
        URL: '參數錯誤',
        UUID: '參數錯誤',
    },
}
