import { createApp } from 'vue'
import installElementPlus from '@/plugins/element'
import installElementIcon from '@/plugins/elementIcon'
import i18n from '@/plugins/i18n'
import store from './store'
import router from './router'
import App from './App.vue'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

let app = createApp(App)

installElementPlus(app)
installElementIcon(app)

app.use(store).use(router).use(i18n).use(VueClipboard).mount('#app')
