import request from '@/utils/request'
// 取得商戶站名稱
export const getDomainName = (query) => {
    return request({
        url: 'login/getDomainName',
        method: 'get',
        params: query,
    })
}
export const login = (query) => {
    // 登入
    return request({
        url: 'login/login',
        method: 'post',
        data: query,
    })
}

// 未攜帶裝置登入
export const otpLogin = (query) => {
    return request({
        url: 'login/otpLogin',
        method: 'post',
        data: query,
    })
}

// 確認驗證碼
export const verifyValidateCode = (query) => {
    return request({
        url: 'login/verifyValidateCode',
        method: 'post',
        data: query,
    })
}

// Admin-webauthn 登入
export const webAuthnLogin = (query) => {
    return request({
        url: 'login/webauthnLogin',
        method: 'post',
        data: query,
    })
}

// Magic-link 轉址
export const redirectInfo = (query) => {
    return request({
        url: 'login/redirect',
        method: 'post',
        data: query,
    })
}

// 登出
export const logout = () => {
    return request({
        url: 'login/logout',
        method: 'post',
    })
}

// 取得使用者資訊
export const userInfo = () => {
    return request({
        url: 'login/me',
        method: 'get',
    })
}
