import i18n from '@/plugins/i18n'
import { routeMiddleware } from '@/utils/middleware'
const { t } = i18n.global

export default [
    {
        path: '/merchant',
        component: () => import('@/layout/merchant.vue'),
        beforeEnter: routeMiddleware,
        children: [
            {
                path: 'appManagement',
                name: 'appManagement',
                meta: {
                    title: t('menu.merchant.appManagement'),
                    title_i18n: 'menu.merchant.appManagement',
                },
                component: () => import('@/views/Merchant/AppManagement/AppManagement.vue'),
            },
            {
                path: 'system',
                name: 'merchant-system',
                redirect: 'noRedirect',
                meta: {
                    title: t('menu.merchant.system.index'),
                    title_i18n: 'menu.merchant.system.index',
                },
                children: [
                    {
                        path: 'detail',
                        name: 'detail',
                        meta: {
                            title: t('menu.merchant.system.detail'),
                            title_i18n: 'menu.merchant.system.detail',
                        },
                        component: () => import('@/views/Merchant/System/MerchantDetail/MerchantDetail.vue'),
                    },
                    {
                        path: 'merchantAccount',
                        name: 'merchant-merchantAccount',
                        meta: {
                            title: t('menu.merchant.system.merchantAccount'),
                            title_i18n: 'menu.merchant.system.merchantAccount',
                        },
                        component: () => import('@/views/Merchant/System/MerchantAccount/MerchantAccount.vue'),
                    },
                    {
                        path: 'rolePermissions',
                        name: 'merchant-rolePermissions',
                        meta: {
                            title: t('menu.merchant.system.rolePermissions'),
                            title_i18n: 'menu.merchant.system.rolePermissions',
                        },
                        component: () => import('@/views/Merchant/System/RolePermissions/RolePermissions.vue'),
                    },
                    {
                        path: 'operationRecord',
                        name: 'merchant-operationRecord',
                        meta: {
                            title: t('menu.merchant.system.operationRecord'),
                            title_i18n: 'menu.merchant.system.operationRecord',
                        },
                        component: () => import('@/views/Merchant/System/OperationRecord/OperationRecord.vue'),
                    },
                ],
            },
        ],
    },
]
